import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"

class SchoolServicesPage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="School services"
          description="View route information and buy your tickets for school services operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent headblock page-schools">
            <h1 className="headblock__heading">School services</h1>
            <p className="headblock__byline">
              Buy tickets and view route information for our home to school
              services
            </p>
            <p className="headblock__byline" style={{ marginTop: 15 }}>
              <a href="https://passenger.shuttleid.uk">View My Tickets</a>
            </p>
          </div>
          <div className="body gutter page-content-parent page-schools">
            <div className="page-content">
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Rossett School</h2>

                <div className="content-box__list">
                  <ul style={{ width: '100%' }}>
                    <li>
                      <Link to="/r10-service/">R10</Link>
                    </li>
                    <li>
                      <Link to="/rossett-late-bus-service/">Late bus service</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Heckmondwike Grammar</h2>
                <div className="content-box__list">
                  <ul style={{ width: '100%' }}>
                    <li>
                      <Link to="/hgs11-service/">HGS11</Link>
                    </li>
                    <li>
                      <Link to="/hgs12-service/">HGS12</Link>
                    </li>
                    <li>
                      <Link to="/hgs13-service/">HGS13</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Bishop Young Academy</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/by25-service/">BY25</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Farnley Academy</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/fa1-service/">FA1</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Cockburn School/Laurence Calvert Academy</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/s62-service/">S62</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Bradford Grammar School</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/bg1-service/">BG1</Link>
                    </li>
                    <li>
                      <Link to="/bg2-service/">BG2</Link>
                    </li>
                    <li>
                      <Link to="/bg3-service/">BG3</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Leeds City Academy</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/lc1-service/">LC1</Link>
                    </li>
                    <li>
                      <Link to="/lc2-service/">LC2</Link>
                    </li>
                    <li>
                      <Link to="/lc3-service/">LC3</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Brigshaw High School</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/br2-service/">BR2</Link>
                    </li>
                    <li>
                      <Link to="/br11-service/">BR11</Link>
                    </li>
                    <li>
                      <Link to="/br12-service/">BR12</Link>
                    </li>
                    <li>
                      <Link to="/br13-service/">BR13</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Garforth Academy</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/g1-service/">G1</Link>
                    </li>
                    <li>
                      <Link to="/g2-service/">G2</Link>
                    </li>
                    <li>
                      <Link to="/g4-service/">G4</Link>
                    </li>
                    <li>
                      <Link to="/526-service/">526</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Methley Primary School</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/p53-service/">P53</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Sacred Heart RC Primary School</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/p89-service/">P89</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Rodillian Academy</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/574-service/">574</Link>
                    </li>
                    <li>
                      <Link to="/575-service/">575</Link>
                    </li>
                    <li>
                      <Link to="/l73-service/">L73</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Leeds East Academy</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/le1-service/">LE1</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Oulton Academy</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/r5-service/">R5</Link>
                    </li>
                    <li>
                      <Link to="/154-service/">154</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Leeds City College</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/tn1-service/">TN1</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Gateways School</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/gateways-service/">Gateways service</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Rothwell Primary School &amp; St Mary's Rothwell</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/p54-service/">P54</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Lady Elizabeth Hastings School</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/s20-service/">S20</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="page-content">
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">St Benedict's School</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/g3-service/">G3</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="page-content__text content-box">
                <h2 className="content-box__heading">Corpus Christi Catholic College</h2>
                <div className="content-box__list">
                  <ul>
                    <li>
                      <Link to="/cc1-service/">CC1</Link>
                    </li>
                    <li>
                      <Link to="/cc2-service/">CC2</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <p className="info-box">
              Looking to hire a bus for a school trip or contract?{" "}
              <span className="block">
                Read more about our{" "}
                <Link to="/coach-hire-services/school-trips/">
                  school bus hire
                </Link>
                .
              </span>
            </p>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default SchoolServicesPage
